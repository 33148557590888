import _axios, { get, put, post, _delete } from '@/lin/plugin/axios'

class System {
  // 获取系统配置
  async getSystemConfig(data) {
    const res = await get('/v1/system', data)
    return res
  }

  // 修改系统配置
  async editSystemConfig(data) {
    const res = await post('/v1/system', data)
    return res
  }
}

export default new System()
