<template>
    <div class="container">
        <div class="header">
            <div class="handle">
                <div class="title">系统配置</div>
            </div>
        </div>

        <div>
            <el-row>
                <el-col :xl="12" :lg="20" :md="20" :sm="24" :xs="24">
                    <el-form ref="form" :model="formData" label-width="80px">
                        <el-form-item label="站点名称" prop="site_name">
                            <el-input v-model="formData.site_name" placeholder="请填写站点名称" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="客服热线" prop="hot_line">
                            <el-input v-model="formData.hot_line" placeholder="请填写客服热线" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="email">
                            <el-input v-model="formData.email" placeholder="请填写邮箱" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="二维码" prop="qrcode_img_id">
                            <upload-imgs ref="qrcode_img_id" :value="initImg" :multiple="false" :maxNum="1" />
                        </el-form-item>
                        <el-form-item label="站点描述" prop="describe">
                            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 8 }" v-model="formData.describe" placeholder="请填写站点描述" clearable></el-input>
                        </el-form-item>

                        <el-form-item class="submit">
                            <el-button type="primary" @click="submitForm" v-loading="loading">保 存</el-button>
                            <el-button @click="resetForm">重 置</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, reactive, getCurrentInstance, toRow } from 'vue'
import UploadImgs from '@/component/base/upload-image'
import systemModel from '@/model/system'
import { ElMessage } from 'element-plus'

export default {
    components: {
        UploadImgs,
    },
    setup(props, ctx) {
        const form = ref(null)
        const qrcode_img_id = ref(null)
        const formData = ref({})
        const initImg = ref([])
        const loading = ref(false)

        const getData = async () => {
            let res = await systemModel.getSystemConfig()
            formData.value = res
            initImg.value = [{
                id: res.img?.id,
                display: res.img?.url,
                src: res.img?.url,
                imgId: res.img?.id
            }]
        }

        const submitForm = async () => {
            let img = await qrcode_img_id.value.getValue()
            if (img.length) formData.value.qrcode_img_id = img[0].imgId
            try {
                loading.value = true
                let res = await systemModel.editSystemConfig(formData.value)
                ElMessage.success(res.message)
            } finally {
                loading.value = false
            }
        }

        const resetForm = () => {
            initImg.value = []
            console.log(form.value);
            form.value.resetFields()
        }

        onMounted(() => {
            getData()
        })

        return {
            form,
            qrcode_img_id,
            formData,
            initImg,
            loading,
            submitForm,
            resetForm
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/realize/custom';

</style>

